import styled from 'styled-components';

import { MIN_WIDTH_LG } from '../../../theme/breakpoints';
import { color } from '../../../theme/functions';

import { Paper } from '../../common';
import { THEME_DENTOLO, THEME_PETOLO, THEME_VITOLO } from '../../../shared';

export const StyledClaimStatusInfoCard = styled(Paper)`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 1em;
  padding: 1em;
`;

export const StyledClaimStatusUpdatedTime = styled.div`
  margin-bottom: 1em;
  font-size: 1em;
  font-weight: 300;
  line-height: 1.25em;
  color: ${color('dark_gray_blue')};
  opacity: 0.9;
`;

export const StyledClaimStatusTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  h5 {
    font-size: 1.25rem;
    line-height: 1.25rem;
  }

  @media (min-width: ${MIN_WIDTH_LG}) {
    h5 {
      line-height: 1.5rem;
    }
  }
`;

export const StyledClaimStatusDetails = styled.div`
  margin-top: 1em;
  font-size: 1em;
  font-weight: 300;
  line-height: 1.25em;
  color: ${color('dark_gray_blue')};
  opacity: 0.9;
`;

const helpcenterArticleBackgroundColor = (theme) => {
  switch (theme?.name) {
    case THEME_DENTOLO:
      return color('clear_light_blue');
    case THEME_PETOLO:
      return color('ultra_light_orange');
    case THEME_VITOLO:
      return color('light_violet');
    default:
      return color('clear_light_blue');
  }
};

export const StyledHelpCenterArticleContainer = styled.div`
  background-color: ${({ theme }) => {
    return helpcenterArticleBackgroundColor(theme);
  }};
  padding: 1rem;
  margin-top: 1rem;
`;

export const StyledUserClaimComments = styled.p`
  margin-top: 1em;
  font-size: 1em;
  font-weight: 300;
  line-height: 1.25em;
  border-radius: 2px;
  padding: 0.5em;
  opacity: 0.9;
  background-color: ${color('gray_blue')};
`;

export const StyledUploadDocumentButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1em;
  padding: 0 1em;
`;

export const StyledClaimDocumentsDownloadContainer = styled.div`
  background: ${color('ultra_clear_light_blue')};
  padding: 1rem;
  margin-top: 1rem;
`;

export const StyledClaimDocumentsDownloadTextTitle = styled.p`
  font-size: 0.875rem;
  line-height: 1.125;
`;

export const StyledClaimDocumentsDownloadTextDescription = styled.p`
  font-size: 1rem;
  line-height: 1.5;
`;
