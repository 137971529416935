import styled from 'styled-components';

import { MIN_WIDTH_MD } from '../../../theme/breakpoints';
import { color } from '../../../theme/functions';

import { Button } from '../../../components/ui';

export const StyledReferralLinkCardDentolo = styled.div`
  position: relative;
  padding: 0 0 1rem 1rem;
  height: 220px;
  background-color: ${color('ultra_clear_light_blue')};
  background: url(${({ backgroundImgMobile }) => backgroundImgMobile}) no-repeat;
  background-size: cover;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 157px;
    height: 134px;
    background: url(${({ imgMobile }) => imgMobile}) no-repeat;
    background-size: 157px 134px;
  }

  @media (min-width: ${MIN_WIDTH_MD}) {
    padding: 1.5rem;
    height: 300px;
    background: url(${({ backgroundImgDesktop }) => backgroundImgDesktop}) no-repeat;

    &::before {
      top: 1rem;
      right: 1.5rem;
      width: 286px;
      height: 262px;
      background: url(${({ imgDesktop }) => imgDesktop}) no-repeat;
      background-size: 286px 262px;
    }
  }
`;

export const StyledReferralLinkCardDentoloContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
  height: 100%;

  @media (min-width: ${MIN_WIDTH_MD}) {
    justify-content: flex-start;
    margin-top: 2rem;
  }
`;

export const StyledReferralLinkCardDentoloText = styled.h1`
  column-gap: 0.75rem;
  margin-bottom: 2.75rem;
  font-size: 1.5rem;
  font-weight: 700;

  > span {
    display: inline;
    padding-left: 2px;
    padding-right: 2px;
    background: linear-gradient(to bottom, transparent 62%, ${color('main_green')} 38%);
    background-repeat: no-repeat;
  }

  @media (min-width: ${MIN_WIDTH_MD}) {
    margin-bottom: 1.5rem;
    font-size: 1.875rem;

    > span {
      padding-left: 3px;
      padding-right: 3px;
    }
  }
`;

export const StyledReferralLinkCardDentoloButton = styled(Button)`
  position: absolute;
  bottom: 1rem;
  left: 1rem;
  right: 1rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  width: auto;
  height: 3.5rem;
  font-size: 1.25rem;
  font-weight: 700;

  @media (min-width: ${MIN_WIDTH_MD}) {
    position: relative;
    bottom: initial;
    left: initial;
    right: initial;
    width: 100%;
    height: 4rem;
    font-size: 1.5rem;
  }
`;
