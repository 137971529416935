import React from 'react';
import * as claimStatuses from './claimStatus';
import { INSURANCE_TYPE_DENTOLO, INSURANCE_TYPE_PETOLO } from './constants';

// linting comment: adding export to fix linting error
export const getDoctorTitle = (insuranceType) => {
  switch (insuranceType) {
    case INSURANCE_TYPE_DENTOLO:
      return `Zahnarzt`;
    case INSURANCE_TYPE_PETOLO:
      return `Tierarzt`;
    default:
      return `Zahnarzt`;
  }
};

export const claimStatusExplanation = {
  // Neu
  claim_opened: {
    status: claimStatuses.NEW,
    statusDetails: 'Leistungsfall eröffnet',
    statusExplanation: ({ insuranceType }) =>
      `${insuranceType === INSURANCE_TYPE_PETOLO ? 'Ihr Leistungsfall wurde angelegt. Die Bearbeitung kann aktuell bis zu 14 Tage dauern. Wir bedauern die Verzögerung, werden Ihre Unterlagen aber schnellstmöglich prüfen und Sie per E-Mail über weitere Schritte informieren.' : 'Ihr Leistungsfall wurde angelegt. Die Prüfung dauert in der Regel bis zu fünf Werktage. Wir melden uns bei Ihnen sobald es Neuigkeiten gibt.'} `,
  },

  claim_updated: {
    status: claimStatuses.UPDATED,
    statusDetails: 'Leistungsfall aktualisiert',
    statusExplanation: () =>
      `Ihr Dokument wurde erstellt. Die Bearbeitung kann derzeit bis zu 14 Tage dauern. Wir bedauern die Verzögerung, werden Ihre Unterlagen aber so schnell wie möglich prüfen und Sie per E-Mail über weitere Schritte informieren.`,
  },

  // Wartend
  treatment_or_costplan_missing: {
    status: claimStatuses.WAITING_FOR_INFORMATION,
    statusDetails: 'Genehmigter Heil- und Kostenplan fehlt',
    statusExplanation: () =>
      `Zur Bearbeitung Ihres Leistungsfalls benötigen wir noch den von Ihrer Krankenkasse genehmigten Heil- und Kostenplan.`,
  },
  information_request_missing: {
    status: claimStatuses.WAITING_FOR_INFORMATION,
    statusDetails: 'Auskunftsersuchen fehlt',
    statusExplanation: () =>
      `Wir benötigen die ausgefüllten Dokumente, um Ihre Kostenerstattung zu bearbeiten.`,
    showDownloadDocuments: true,
  },
  patient_file_missing: {
    status: claimStatuses.WAITING_FOR_INFORMATION,
    statusDetails: 'Patientenakte fehlt',
    statusExplanation: () =>
      `Zur Bearbeitung Ihres Leistungfalls benötigen wir eine Kopie Ihrer Patientenakte. Diese können Sie in der Praxis anfordern, die die Behandlung durchführt.`,
  },
  invoice_missing: {
    status: claimStatuses.WAITING_FOR_INFORMATION,
    statusDetails: 'Rechnung fehlt',
    statusExplanation: () =>
      `Zur abschließenden Bearbeitung Ihres Leistungfalls benötigen wir noch die Rechnung Ihrer Behandlung.`,
  },
  documents_missing: {
    status: claimStatuses.WAITING_FOR_INFORMATION,
    statusDetails: 'Unterlagen fehlen',
    statusExplanation: () =>
      `Zur Bearbeitung Ihres Leistungfalls benötigen wir noch weitere Unterlagen.`,
  },
  pet_medical_card_is_missing: {
    status: claimStatuses.WAITING_FOR_INFORMATION,
    statusDetails: 'Krankenakte fehlt',
    statusExplanation: ({ insuranceType }) =>
      `Zur Bearbeitung Ihres Leistungfalls benötigen wir eine Kopie Ihrer Krankenakte. Diese können Sie bei Ihrem ${getDoctorTitle(
        insuranceType
      )} anfordern.`,
  },
  // Deckungszusage
  reimbursement_acknowledged: {
    status: claimStatuses.REIMBURSEMENT_ACKNOWLEDGED,
    statusDetails: 'Deckung zugesagt',
    statusExplanation: ({ isReimbursment, reimbursableAmount }) => {
      return isReimbursment ? (
        <span>
          Gemäß unseren Versicherungsbedingungen erstatten wir <strong>{reimbursableAmount}</strong>{' '}
          für Ihre anstehende Behandlung. Unsere Zusage basiert auf den von Ihnen eingereichten
          Unterlagen.
        </span>
      ) : (
        `Wir freuen uns Ihnen eine Deckungszusage geben zu können. Für die Erstattung benötigen wir noch die Rechnung Ihrer Behandlung.`
      );
    },
  },
  partial_reimbursement_acknowledged: {
    status: claimStatuses.REIMBURSEMENT_ACKNOWLEDGED,
    statusDetails: 'Teildeckung zugesagt',
    statusExplanation: ({ isReimbursment, reimbursableAmount }) => {
      return isReimbursment ? (
        <span>
          Gemäß unseren Versicherungsbedingungen erstatten wir{' '}
          <strong>{reimbursableAmount} €</strong> für Ihre anstehende Behandlung. Unsere Zusage
          basiert auf den von Ihnen eingereichten Unterlagen.
        </span>
      ) : (
        `Wir freuen uns Ihnen eine Deckungszusage geben zu können, benötigen jedoch noch die Rechnung Ihrer Behandlung.`
      );
    },
  },
  // Auszahlung
  reimbursement_will_be_paid: {
    status: claimStatuses.WAITING_FOR_PAYOUT,
    statusDetails: 'Erstattung wird ausgezahlt',
    statusExplanation: ({ payoutAmount, recepientIban, recepientName, isSameIban }) => {
      return isSameIban
        ? `Die Erstattung in Höhe von ${payoutAmount} wird auf folgendes Konto überwiesen ${recepientIban}.` // recepient IABN == customer IBAN
        : `Die Erstattung in Höhe von ${payoutAmount} wird auf das Konto von ${recepientName} mit der IBAN ${recepientIban} überwiesen.`; // recepient IBAN != customer IBAN
    },
  },
  partial_reimbursement_will_be_paid: {
    status: claimStatuses.WAITING_FOR_PAYOUT,
    statusDetails: 'Teil–Erstattung wird ausgezahlt',
    statusExplanation: ({ payoutAmount, recepientIban, recepientName, isSameIban }) => {
      return isSameIban
        ? `Die Erstattung in Höhe von ${payoutAmount} wird auf folgendes Konto überwiesen ${recepientIban}.` // recepient IABN == customer IBAN
        : `Die Erstattung in Höhe von ${payoutAmount} wird auf das Konto von ${recepientName} mit der IBAN ${recepientIban} überwiesen.`; // recepient IBAN != customer IBAN
    },
  },
  // Ausgezahlt
  reimbursement_paid: {
    status: claimStatuses.CLOSED,
    statusDetails: 'Erstattung ausgezahlt',
    statusExplanation: ({ payoutAmount, recepientIban, recepientName, isSameIban }) => {
      return isSameIban
        ? `Die Erstattung in Höhe von ${payoutAmount} wurde auf folgendes Konto überwiesen ${recepientIban}.` // recepient IABN == customer IBAN
        : `Die Erstattung in Höhe von ${payoutAmount} wurde auf das Konto von ${recepientName} mit der IBAN ${recepientIban} überwiesen.`; // recepient IBAN != customer IBAN
    },
  },
  partial_reimbursement_paid: {
    status: claimStatuses.CLOSED,
    statusDetails: 'Teil–Erstattung ausgezahlt',
    statusExplanation: ({ payoutAmount, recepientIban, recepientName, isSameIban }) => {
      return isSameIban
        ? `Die Erstattung in Höhe von ${payoutAmount} wurde auf folgendes Konto überwiesen ${recepientIban}.` // recepient IBAN == customer IBAN
        : `Die Erstattung in Höhe von ${payoutAmount} wurde auf das Konto von ${recepientName} mit der IBAN ${recepientIban} überwiesen.`; // recepient IBAN != customer IBAN
    },
  },
  // Blockiert
  contract_signature_missing: {
    status: claimStatuses.BLOCKED,
    statusDetails: 'Vertragsbestätigung fehlt',
    statusExplanation: () => `Wir freuen uns Ihnen eine Deckungszusage geben zu können.`,
  },
  withdrawal_period_still_running: {
    status: claimStatuses.BLOCKED,
    statusDetails: 'Widerrufsfrist läuft noch',
    statusExplanation: () =>
      `Wir freuen uns Ihnen eine Deckungszusage geben zu können. Die Auszahlung der Erstattung erfolgt nach Ablauf der 14-tägigen Widerrufsfrist.`,
  },
  outstanding_payments: {
    status: claimStatuses.BLOCKED,
    statusDetails: 'Ausstehende Beitragszahlungen',
    statusExplanation: () =>
      `Wir freuen uns Ihnen eine Deckungszusage geben zu können. Für die Auszahlung der Erstattung möchten wir Sie bitten zunächst die noch ausstehenden Beitragszahlungen auszugleichen.`,
  },
  trial_month: {
    status: claimStatuses.BLOCKED,
    statusDetails: 'Versicherung/Tarif noch nicht aktiv (Probemonat)',
    statusExplanation: () =>
      `Wir freuen uns Ihnen eine Deckungszusage geben zu können. Die Auszahlung der Erstattung erfolgt nach Ablauf Ihres kostenlosen Probemonats.`,
  },
  // Abgelehnt
  /* TODO: update below statuse keys when implemented at the backend
  updated_one: {
    status: claimStatuses.DECLINED,
    statusDetails: `Versicherung nicht aktiv`,
    statusExplanation: () => `Leider können wir Ihnen keine Erstattung zusagen, da Ihr Versicherungsschutz nicht mehr aktiv ist.`,
  },
  updated_two: {
    status: claimStatuses.DECLINED,
    statusDetails: `Behandelte Person ist nicht versicherte Person`,
    statusExplanation: () => `Leider können wir Ihnen keine Erstattung zusagen, da die behandelte Person nicht über Ihren Vertrag versichert ist.`,
  }, */
  treatment_before_contract_start: {
    status: claimStatuses.DECLINED,
    statusDetails: 'Behandlung erfolgte vor Versicherungsbeginn',
    statusExplanation: () =>
      `Leider können wir Ihnen keine Erstattung zusagen, da die Behandlung bereits vor Versicherungsbeginn erfolgte.`,
  },
  no_coverage: {
    status: claimStatuses.DECLINED,
    statusDetails: 'Keine Deckung durch den Versicherungstarif',
    statusExplanation: () =>
      `Leider können wir Ihnen keine Erstattung zusagen, da diese Behandlung durch Ihren Tarif nicht abgedeckt ist.`,
  },
  known_of_treatment_before_contract_start_by_plan: {
    status: claimStatuses.DECLINED,
    statusDetails:
      'Behandlungsbedürftigkeit laut Heil- und Kostenplan vor Versicherungsbeginn bekannt',
    statusExplanation: () =>
      `Leider können wir Ihnen keine Erstattung zusagen, da die durch den Heil- und Kostenplan angegebenen Behandlungen bereits vor dem Beginn Ihres Versicherungsschutzes als erforderlich erachtet wurden.`,
  },
  known_of_treatment_before_contract_start_by_dentist_request: {
    status: claimStatuses.DECLINED,
    statusDetails:
      'Behandlungsbedürftigkeit laut Auskunftsersuchen des Zahnarztes vor Versicherungsbeginn bekannt',
    statusExplanation: () =>
      `Leider können wir Ihnen keine Erstattung zusagen, da die behandelten Zähne laut Auskunftsersuchen Ihres Zahnarztes bereits vor Versicherungsbeginn behandlungsbedürftig sind / waren.`,
  },
  known_of_treatment_before_contract_start_by_pretreatment_provide: {
    status: claimStatuses.DECLINED,
    statusDetails:
      'Behandlungsbedürftigkeit laut Auskunftsersuchen des Vorbehandlers vor Versicherungsbeginn bekannt',
    statusExplanation: () =>
      `Leider können wir Ihnen keine Erstattung zusagen, da die behandelten Zähne laut Auskunftsersuchen Ihres Vorbehandlers bereits vor Versicherungsbeginn behandlungsbedürftig sind / waren.`,
  },
  treatment_before_contract_start_by_invoice: {
    status: claimStatuses.DECLINED,
    statusDetails: 'Behandlung erfolgte laut Rechnung vor Versicherungsbeginn',
    statusExplanation: () =>
      `Leider können wir Ihnen keine Erstattung zusagen, da die Behandlung vor Versicherungsbeginn erfolgt ist.`,
  },
  no_budget: {
    status: claimStatuses.DECLINED,
    statusDetails: 'Leistungsbegrenzung ausgeschöpft',
    statusExplanation: () =>
      `Leider können wir Ihnen keine Erstattung zusagen, da ihr Leistungsbudget bereits ausgeschöpft ist.`,
  },
  no_coverage_no_medical_need: {
    status: claimStatuses.DECLINED,
    statusDetails:
      'Keine Deckung durch den Versicherungstarif, da keine medizinische Notwendigkeit',
    statusExplanation: () =>
      `Leider können wir Ihnen keine Erstattung zusagen, da keine medizinische Notwendigkeit der Behandlung vorliegt.`,
  },
  no_coverage_orthodontics: {
    status: claimStatuses.DECLINED,
    statusDetails:
      'Keine Deckung durch den Versicherungstarif / Kieferorthopädie durch Tarif nicht abgedeckt',
    statusExplanation: () =>
      `Leider können wir Ihnen keine Erstattung zusagen, da kieferorthopädische Behandlungen nicht von Ihrem Zahnschutz-Tarif abgedeckt sind.`,
  },
  coverage_for_children_only_for_accident: {
    status: claimStatuses.DECLINED,
    statusDetails: 'Abdeckung von Kindern nur bei Unfall',
    statusExplanation: () =>
      `Leider können wir Ihnen keine Erstattung zusagen, da bei Ihnen mitversicherte Kinder nur bei Unfall abgedeckt sind.`,
  },
  max_amount_of_tooth_cleanings_reached: {
    status: claimStatuses.DECLINED,
    statusDetails: 'Anzahl maximal gedeckter Professioneller Zahnreinigungen pro Jahr erreicht',
    statusExplanation: () =>
      `Sie haben leider die maximal erstattbare Anzahl an Professionellen Zahnreinigungen für dieses Versicherungsjahr erreicht.`,
  },
  no_coverage_cosmetic_measures: {
    status: claimStatuses.DECLINED,
    statusDetails: 'Keine Deckung von kosmetischen Maßnahmen',
    statusExplanation: () =>
      `Leider können wir Ihnen keine Erstattung zusagen, da es sich bei der Behandlung um eine rein kosmetische Maßnahme handelt.`,
  },
  not_the_insured_animal: {
    status: claimStatuses.DECLINED,
    statusDetails: 'Behandeltes Tier ist nicht das versicherte Tier',
    statusExplanation: () =>
      `Leider können wir Ihnen keine Erstattung zusagen, da das behandelte Tier nicht über Ihren Vertrag versichert ist.`,
  },
  pet_health_budget_exhausted: {
    status: claimStatuses.DECLINED,
    statusDetails: 'Gesundheitsbudget ausgeschöpft',
    statusExplanation: () =>
      `Leider können wir Ihnen keine Erstattung zusagen, da ihr Gesundheitsbudget für dieses Versicherungsjahr bereits ausgeschöpft ist.`,
  },
  pet_sos_budget_exhausted: {
    status: claimStatuses.DECLINED,
    statusDetails: 'SOS-Budget ausgeschöpft',
    statusExplanation: () =>
      `Leider können wir Ihnen keine Erstattung zusagen, da ihr SOS-Budget für dieses Versicherungsjahr bereits ausgeschöpft ist.`,
  },
  known_of_treatment_before_contract_start_by_veterinarian_request: {
    status: claimStatuses.DECLINED,
    statusDetails:
      'Behandlungsbedürftigkeit laut Auskunftsersuchen Ihres Tierarztes vor Versicherungsbeginn bekannt',
    statusExplanation: () =>
      `Leider können wir Ihnen keine Erstattung zusagen, da Ihr Tier laut Auskunftsersuchen Ihres Tierarztes bereits vor Versicherungsbeginn behandlungsbedürftig war.`,
  },
  known_of_treatment_before_contract_start_by_vaterinarian_pretreatment_provide: {
    status: claimStatuses.DECLINED,
    statusDetails:
      'Behandlungsbedürftigkeit laut Auskunftsersuchen des vorbehandelten Tierarztes vor Versicherungsbeginn bekannt',
    statusExplanation: () =>
      `Leider können wir Ihnen keine Erstattung zusagen, da die Behandlungsbedürftigkeit gemäß Videokonsultation bereits vor Versicherungsbeginn bekannt war.`,
  },
  firstvet_treatment_needed: {
    status: claimStatuses.DECLINED,
    statusDetails:
      'Behandlungsbedürftigkeit laut Videokonsultation vor Versicherungsbeginn bekannt',
    statusExplanation: () =>
      `Leider können wir Ihnen keine Erstattung zusagen, da die Behandlungsbedürftigkeit gemäß Videokonsultation bereits vor Versicherungsbeginn bekannt war.`,
  },
  treatment_person_is_not_insured: {
    status: claimStatuses.DECLINED,
    statusDetails: 'Behandelte Person entspricht nicht versicherter Person',
    statusExplanation: () =>
      `Leider können wir Ihnen keine Erstattung zusagen, da die behandelte Person nicht der versicherten Person entspricht.`,
  },
  reimbursement_is_already_covered: {
    status: claimStatuses.DECLINED,
    statusDetails: 'Festzuschuss der Krankenkasse deckt Erstattungsbetrag',
    statusExplanation: () =>
      `Leider können wir Ihnen keine Erstattung zusagen, da die Krankenversicherung bereits den gemäß Ihres Tarifs erstattungsfähigen Betrag abdeckt.`,
  },
  copayment_is_already_covered: {
    status: claimStatuses.DECLINED,
    statusDetails: 'Krankenkasse deckt den verbleibenden Eigenanteil',
    statusExplanation: () =>
      `Leider können wir Ihnen keine Erstattung zusagen, da nach erfolgreichem Abschluss der Behandlung Ihnen der Versichertenanteil von der gesetzl. Krankenversicherung erstattet wird.`,
  },
  treatment_was_known_before_insurance: {
    status: claimStatuses.DECLINED,
    statusDetails: 'Behandlungsbedürftigkeit laut Patientenakte vor Versicherungsbeginn bekannt',
    statusExplanation: () =>
      `Leider können wir Ihnen keine Erstattung zusagen, da die Behandlungsbedürftig laut der Patientenakte bereits vor Versicherungsbeginn bestand.`,
  },
  treatment_before_contract_sign: {
    status: claimStatuses.DECLINED,
    statusDetails: 'Behandlung erfolgte vor Vertragsabschluss',
    statusExplanation: () =>
      `Leider können wir Ihnen keine Erstattung zusagen, da die Behandlung bereits vor Vertragsabschluss erfolgte.`,
  },
  treatment_needs_before_contract_sign: {
    status: claimStatuses.DECLINED,
    statusDetails: 'Behandlungsbedürftigkeit vor Vertragsabschluss bekannt',
    statusExplanation: () =>
      `Leider können wir Ihnen keine Erstattung zusagen, da die Behandlungsbedürftigkeit bereits vor Vertragsabschluss bekannt war.`,
  },
  treatment_was_known_before_insurance_by_dentist: {
    status: claimStatuses.DECLINED,
    statusDetails:
      'Behandlungsbedürftigkeit laut Auskunftsersuchen Ihres Kieferorthopäden vor Versicherungsbeginn bekannt',
    statusExplanation: () =>
      `Leider können wir Ihnen keine Erstattung zusagen, da die behandelten Zähne laut Auskunftsersuchen Ihres Kieferorthopäden bereits vor Versicherungsbeginn behandlungsbedürftig sind/waren.`,
  },
  known_of_treatment_before_contract_start_by_doctor_request: {
    status: claimStatuses.DECLINED,
    statusDetails:
      'Behandlungsbedürftigkeit laut Auskunftsersuchen Ihrer Arztpraxis vor Versicherungsbeginn bekannt',
    statusExplanation: () =>
      `Leider können wir Ihnen keine Erstattung zusagen, da die Notwendigkeit der Behandlung laut Auskunftsersuchen bereits vor Versicherungsbeginn bekannt war.`,
  },
  coverage_of_costs_through_third_party_damage_claims: {
    status: claimStatuses.DECLINED,
    statusDetails: 'Kostendeckung durch Schadensansprüche Dritte',
    statusExplanation: () =>
      `Leider können wir Ihnen keine Erstattung zusagen, da Ihnen der Versichertenanteil durch einen weiteren Schadensanspruch zusteht.`,
  },
  // Storniert
  canceled_by_dentolo: {
    status: claimStatuses.CANCELLED,
    statusDetails: 'Auf Veranlassung von dentolo',
    statusExplanation: () => `Wir haben Ihren Leistungsfall storniert.`,
  },
  canceled_by_customer: {
    status: claimStatuses.CANCELLED,
    statusDetails: 'Auf Veranlassung des Kunden',
    statusExplanation: () => `Wir haben Ihren Leistungsfall auf Ihren Wunsch hin storniert.`,
  },
};
